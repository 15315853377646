/**
 * 
 * @returns Obtiene el ViewPort de la ventana
 */
function getViewPort() {
    var e = window,
        a = 'inner';
    if (!('innerWidth' in window)) {
        a = 'client';
        e = document.documentElement || document.body;
    }

    return {
        width: e[a + 'Width'],
        height: e[a + 'Height']
    };
}

/**
 * Gestiona el menu superior en pantallas moviles
 */
function handleMenuToggler(){
    if (getViewPort().width < 992) {
        var menu = $(".page-header .page-header-menu");
        if (menu.is(":visible")) {
            menu.slideUp(300);
        } else {  
            menu.slideDown(300);
        }
    }
}